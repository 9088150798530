@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

body {
  color: #363636;
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow-x: hidden;
}

#header {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 80px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

@media screen and (max-width: 1358px) {
  #header {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1200px) {
  #header {
    padding: 0 0 0 20px;
  }
}

@media screen and (max-width: 990px) {
  #header {
    padding: 18px 20px;
  }
}

#header .inner {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

#header .inner .logo {
  width: 250px;
}

@media screen and (max-width: 1200px) {
  #header .inner .logo {
    width: 180px;
  }
}

#header .inner .nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 990px) {
  #header .inner .nav-menu {
    display: none;
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    padding: 35px 15px 80px;
    overflow-y: scroll;
  }
  #header .inner .nav-menu.active {
    display: block;
  }
}

#header .inner .nav-menu .show-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-right: 19px;
  width: calc(100% - 230px);
}

@media screen and (max-width: 1358px) {
  #header .inner .nav-menu .show-menu {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1200px) {
  #header .inner .nav-menu .show-menu {
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 990px) {
  #header .inner .nav-menu .show-menu {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 35px;
    border-top: 1px solid #949494;
  }
}

#header .inner .nav-menu .show-menu > .item {
  position: relative;
  padding: 0 14px;
}

@media screen and (max-width: 1200px) {
  #header .inner .nav-menu .show-menu > .item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 990px) {
  #header .inner .nav-menu .show-menu > .item {
    width: 50%;
    padding: 0;
  }
  #header .inner .nav-menu .show-menu > .item:nth-child(2n) a {
    border-left: 1px solid #949494;
  }
}

#header .inner .nav-menu .show-menu > .item > a {
  display: block;
  font-weight: bold;
  color: #363636;
  position: relative;
  padding: 10px 0;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 1200px) {
  #header .inner .nav-menu .show-menu > .item > a {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 990px) {
  #header .inner .nav-menu .show-menu > .item > a {
    padding: 10px 5px;
    text-align: center;
    border-bottom: 1px solid #949494;
  }
}

#header .inner .nav-menu .show-menu > .item > a:before {
  content: '';
  background: #6eb944;
  width: 90%;
  height: 2px;
  bottom: 0;
  left: 50%;
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50%);
}

#header .inner .nav-menu .show-menu > .item > a:hover {
  color: #6eb944;
}

#header .inner .nav-menu .show-menu > .item > a:hover:before {
  opacity: 1;
  transition: all 0.3s;
}

#header .inner .nav-menu .show-menu > .item.active a {
  color: #6eb944;
}

#header .inner .nav-menu .show-menu > .item.active a:before {
  opacity: 1;
}

#header .inner .nav-menu .phone-hd {
  width: 237px;
  text-align: center;
  background: #ff8331;
  padding: 3px 10px 18px 5px;
}

@media screen and (max-width: 1200px) {
  #header .inner .nav-menu .phone-hd {
    width: 200px;
    padding: 10px;
  }
}

@media screen and (max-width: 990px) {
  #header .inner .nav-menu .phone-hd {
    width: 220px;
    margin: 0 auto;
  }
}

#header .inner .nav-menu .phone-hd a {
  color: #fff;
  display: block;
  font-weight: 600;
  line-height: 1.34;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.5px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 1200px) {
  #header .inner .nav-menu .phone-hd a {
    font-size: 20px;
    font-size: 2rem;
  }
}

#header .inner .nav-menu .phone-hd a span {
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  display: block;
}

#header .inner .nav-menu .phone-hd a img {
  margin-top: -5px;
}

#header .inner .mobile-icon {
  width: 26px;
  height: 30px;
  -webkit-transition: background .5s;
  -o-transition: background .5s;
  transition: background .5s;
  position: absolute;
  right: 20px;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 12;
  display: none;
}

@media screen and (max-width: 990px) {
  #header .inner .mobile-icon {
    display: block;
  }
}

#header .inner .mobile-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -2px;
  background-color: #333;
  font-size: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-radius: 3px;
}

#header .inner .mobile-icon span:before, #header .inner .mobile-icon span:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  content: '';
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  border-radius: 3px;
}

#header .inner .mobile-icon span:before {
  -webkit-transform: translateY(-250%);
  transform: translateY(-250%);
  top: -4px;
}

#header .inner .mobile-icon span:after {
  -webkit-transform: translateY(250%);
  transform: translateY(250%);
  bottom: -4px;
}

#header .inner .mobile-icon.mobile-close span {
  background-color: transparent;
}

#header .inner .mobile-icon.mobile-close span:before, #header .inner .mobile-icon.mobile-close span:after {
  width: 100%;
  background: #333;
}

#header .inner .mobile-icon.mobile-close span:before {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  top: 2px;
}

#header .inner .mobile-icon.mobile-close span:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
  bottom: -2px;
}

#footer {
  border-top: 2px solid #6eb944;
  padding: 42px 0 36px;
}

#footer .footer-flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex {
    flex-wrap: wrap;
  }
}

#footer .footer-flex .logo-ft {
  width: 252px;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .logo-ft {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  #footer .footer-flex .logo-ft img {
    width: 180px;
  }
}

#footer .footer-flex .phone-ft {
  width: 233px;
  color: #ff8331;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.5px;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .phone-ft {
    width: 200px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  #footer .footer-flex .phone-ft {
    margin: 0 auto;
  }
}

#footer .footer-flex .phone-ft span {
  letter-spacing: 0;
  color: #363636;
  text-align: center;
  line-height: 1.315;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  transform: translateX(8px);
  font-family: "Noto Sans JP", "ヒラギノ角ゴPro W6", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .phone-ft span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

#footer .footer-flex .nav-ft {
  width: calc(100% - 460px);
  padding: 0 30px 0 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 9px -11px -12px;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .nav-ft {
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 834px) {
  #footer .footer-flex .nav-ft {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
  }
}

#footer .footer-flex .nav-ft li {
  padding: 0 13px;
  margin-bottom: 15px;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .nav-ft li {
    padding: 0 9px;
  }
}

@media screen and (max-width: 834px) {
  #footer .footer-flex .nav-ft li {
    padding: 0 12px;
  }
}

#footer .footer-flex .nav-ft li a {
  color: #363636;
  transition: all 0.3s;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 990px) {
  #footer .footer-flex .nav-ft li a {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

#footer .footer-flex .nav-ft li a:hover {
  color: #6eb944;
  transition: all 0.3s;
}

.copy-right {
  background: #6eb944;
  padding: 10px 34px 14px 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 834px) {
  .copy-right {
    width: 100%;
    padding: 10px 10px 12px;
  }
}

.is-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.is-locked body {
  -webkit-overflow-scrolling: auto;
}

.inner {
  display: block;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .pc-xl {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.txt-basic {
  line-height: 1.78;
  letter-spacing: 2px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 990px) {
  .txt-basic {
    letter-spacing: 0;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-basic {
    line-height: 1.6;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  background: white;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 15px;
  font-size: 1.5rem;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.pure-input input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pure-input input[type="checkbox"] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.pure-input input[type="checkbox"]:checked + label:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.pure-input input[type="checkbox"]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: #4F8196;
}

.pure-input input[type="radio"] + label:after, .pure-input input[type="radio"] + label:before {
  border-radius: 50%;
}

.pure-input input[type="radio"]:checked + label:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}

.pure-input input[type="radio"]:checked + label:after {
  transform: scale(1);
}

.pure-input label {
  position: relative;
  padding: 5px 5px 5px 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}

.pure-input label:before {
  box-sizing: content-box;
  content: '';
  color: #4F8196;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #4F8196;
  text-align: center;
  transition: all 0.4s ease;
}

.pure-input label:after {
  box-sizing: content-box;
  content: '';
  background-color: #4F8196;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.fade-up {
  opacity: 0;
  -webkit-transform: translate(0px, 120px);
  -ms-transform: translate(0px, 120px);
  transform: translate(0px, 120px);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  -o-transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.fade-up.active {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
}

.sec-mv {
  position: relative;
}

.sec-mv .mv-ttl {
  position: absolute;
  top: 66%;
  transform: translateY(-50%);
  right: 3%;
  background: #7abf56;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  align-content: center;
  padding: 10px 10px 44px;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 1200px) {
  .sec-mv .mv-ttl {
    width: 200px;
    height: 200px;
    padding: 10px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 640px) {
  .sec-mv .mv-ttl {
    width: 150px;
    height: 150px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.sec-mv .mv-ttl span {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 1200px) {
  .sec-mv .mv-ttl span {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 640px) {
  .sec-mv .mv-ttl span {
    margin-bottom: 2px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.slider-home .item {
  outline: none !important;
}

.slider-home .item img {
  width: 100%;
  min-height: 700px;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

@media screen and (max-width: 990px) {
  .slider-home .item img {
    min-height: 500px;
  }
}

@media screen and (max-width: 640px) {
  .slider-home .item img {
    min-height: 400px;
  }
}

.slider-home .slick-dots {
  bottom: 20px;
}

@media screen and (max-width: 834px) {
  .slider-home .slick-dots {
    bottom: 12px;
  }
}

.slider-home .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #6eb944;
}

.slider-home .slick-dots li.slick-active {
  background: #6eb944;
}

.slider-home .slick-dots li button:before {
  display: none;
}

.sec-problems {
  background: url("../img/index/bg-02.png") center repeat;
  background-size: contain;
  padding: 72px 0 67px;
}

@media screen and (max-width: 834px) {
  .sec-problems {
    padding: 50px 0;
  }
}

.sec-problems .txt-basic {
  text-align: center;
}

.heading-ttl {
  margin-bottom: 90px;
}

@media screen and (max-width: 834px) {
  .heading-ttl {
    margin-bottom: 50px;
  }
}

.ttlcomp-01 {
  text-align: center;
  color: #6eb944;
  font-family: "Noto Serif JP", serif;
  padding-bottom: 28px;
  margin-bottom: 29px;
  letter-spacing: 3px;
  position: relative;
  font-weight: bold;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 990px) {
  .ttlcomp-01 {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .ttlcomp-01 {
    padding-bottom: 12px;
    margin-bottom: 20px;
    letter-spacing: 0;
    font-size: 20px;
    font-size: 2rem;
  }
}

.ttlcomp-01:before {
  content: '';
  background: #6eb944;
  width: 80px;
  height: 2px;
  bottom: 0;
  left: 50.25%;
  position: absolute;
  transform: translateX(-50%);
}

@media screen and (max-width: 834px) {
  .ttlcomp-01:before {
    left: 50%;
    width: 50px;
  }
}

.problems-box {
  position: relative;
}

.problems-ttl {
  position: absolute;
  top: -46px;
  left: 50%;
  display: inline-block;
  padding: 14px 50px 20px;
  background: #6eb944;
  transform: translateX(-50%);
  color: #fff;
  font-weight: bold;
  letter-spacing: 2.5px;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 1200px) {
  .problems-ttl {
    letter-spacing: 0;
    padding: 10px 20px;
    top: -35px;
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .problems-ttl {
    padding: 10px;
    transform: translateX(0);
    position: static;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.problems-ttl span {
  display: block;
  margin-top: 6px;
  letter-spacing: 2px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .problems-ttl span {
    letter-spacing: 0;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .problems-ttl span {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.problems-flex {
  border: 4px solid #6eb944;
  display: flex;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1200px) {
  .problems-flex {
    align-items: center;
  }
}

@media screen and (max-width: 834px) {
  .problems-flex {
    flex-wrap: wrap;
    border-width: 2px;
    margin-bottom: 35px;
  }
}

.problems-img {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .problems-img {
    width: 100%;
  }
}

.problems-img img {
  width: 100%;
}

.problems-list {
  width: 50%;
  padding: 72px 34px 50px;
}

@media screen and (max-width: 1200px) {
  .problems-list {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 834px) {
  .problems-list {
    padding: 20px 15px;
    width: 100%;
  }
}

.problems-btn {
  margin-top: -37px !important;
  margin-bottom: 61px !important;
}

@media screen and (max-width: 990px) {
  .problems-btn {
    margin-top: -30px !important;
  }
}

@media screen and (max-width: 834px) {
  .problems-btn {
    margin-top: 0 !important;
    margin-bottom: 50px !important;
  }
}

.problems-item {
  margin-bottom: 27px;
  display: flex;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .problems-item {
    margin-bottom: 20px;
  }
}

.problems-item:last-child {
  margin-bottom: 0;
}

.problems-item__img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .problems-item__img {
    width: 90px;
    height: 90px;
  }
}

.problems-item__ttl {
  padding-left: 27px;
  width: calc(100% - 140px);
  color: #363636;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1.2;
  letter-spacing: 2px;
  padding-bottom: 6px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 1200px) {
  .problems-item__ttl {
    letter-spacing: 0;
    padding-left: 15px;
    width: calc(100% - 90px);
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 990px) {
  .problems-item__ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .problems-item__ttl {
    flex-wrap: wrap;
  }
}

.problems-item__ttl span {
  padding-left: 9px;
  letter-spacing: 3px;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 1200px) {
  .problems-item__ttl span {
    letter-spacing: 0;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .problems-item__ttl span {
    width: 100%;
    display: block;
    margin-top: 5px;
    text-align: left;
    padding-left: 0;
  }
}

.btn {
  background: #00b1dd;
  font-weight: 500;
  color: #fff;
  max-width: 513px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin: 0 auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 60px;
  padding: 10px 40px 10px 10px;
  letter-spacing: 1.85px;
  position: relative;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 990px) {
  .btn {
    height: 60px;
    max-width: 460px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .btn {
    text-align: center;
    padding: 10px 20px;
    max-width: 300px;
  }
}

.btn:before {
  content: '';
  background: url("../img/index/icon-link.png") center no-repeat;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 21px;
}

@media screen and (max-width: 990px) {
  .btn:before {
    right: 15px;
  }
}

.btn-style02 {
  height: 60px;
  max-width: 350px;
}

@media screen and (max-width: 834px) {
  .btn-style02 {
    max-width: 300px;
  }
}

.cleaning-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 4px solid #3adaaf;
  background: #fff;
  padding: 0 31px 0 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1200px) {
  .cleaning-box {
    padding: 0 15px 0 0;
  }
}

@media screen and (max-width: 990px) {
  .cleaning-box {
    flex-wrap: wrap;
    padding: 0;
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  .cleaning-box {
    border-width: 2px;
  }
}

.cleaning-box .btn {
  max-width: 205px;
  height: 59px;
  margin-right: 0;
}

@media screen and (max-width: 990px) {
  .cleaning-box .btn {
    margin: 0 auto 20px;
  }
}

.cleaning-img {
  width: 238px;
}

@media screen and (max-width: 1200px) {
  .cleaning-img {
    width: 200px;
  }
}

@media screen and (max-width: 990px) {
  .cleaning-img {
    width: 100%;
    margin-bottom: 12px;
  }
}

.cleaning-img img {
  width: 100%;
}

.cleaning-ttl {
  padding-left: 26px;
  font-weight: bold;
  color: #3adaaf;
  letter-spacing: 2px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 1200px) {
  .cleaning-ttl {
    letter-spacing: 0;
    padding-left: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 990px) {
  .cleaning-ttl {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 10px;
  }
}

.cleaning-price {
  padding-left: 50px;
  font-weight: bold;
  color: #363636;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .cleaning-price {
    padding-left: 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 990px) {
  .cleaning-price {
    width: 100%;
    padding: 0 15px;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.cleaning-price span {
  letter-spacing: 2.5px;
  font-family: "Oswald", sans-serif;
  padding-left: 10px;
  font-size: 25px;
  font-size: 2.5rem;
}

@media screen and (max-width: 1200px) {
  .cleaning-price span {
    padding-left: 5px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.sec-work {
  padding: 80px 0 100px;
  background: url("../img/index/bg-01.png") #f0f8ec top center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 834px) {
  .sec-work {
    padding: 50px 0;
  }
}

.work-list {
  display: flex;
  margin: 0 -14px 50px;
}

@media screen and (max-width: 834px) {
  .work-list {
    flex-wrap: wrap;
    margin: 0 0 35px;
  }
}

.work-item {
  padding: 0 14px;
  width: 33.33%;
}

@media screen and (max-width: 834px) {
  .work-item {
    width: 100%;
    padding: 0;
    margin-bottom: 35px;
  }
  .work-item:last-child {
    margin-bottom: 0;
  }
}

.work-item a {
  display: block;
  border: 2px solid #6eb944;
  border-radius: 10px;
  padding: 18px 18px 14px;
  background: #fff;
}

@media screen and (max-width: 990px) {
  .work-item a {
    padding: 12px;
  }
}

.work-img {
  margin-bottom: 17px;
  position: relative;
  overflow: hidden;
}

.work-img:before {
  content: "";
  display: block;
  padding-bottom: calc(320/340*100%);
}

.work-img .thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.work-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.work-ttl {
  margin-bottom: 12px;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 2px;
  font-weight: bold;
  color: #6eb944;
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 990px) {
  .work-ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.work-desc {
  color: #363636;
  line-height: 1.8;
  letter-spacing: 1.75px;
  font-size: 14px;
  font-size: 1.4rem;
}

.voice-box {
  border-radius: 20px;
  background: #6eb944;
  padding: 35px 20px 31px;
  margin-top: 60px;
}

@media screen and (max-width: 834px) {
  .voice-box {
    margin-top: 50px;
    padding: 50px 15px;
    border-radius: 10px;
  }
}

.voice-heading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 57px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 3px;
  padding-left: 41px;
  font-size: 26px;
  font-size: 2.6rem;
}

@media screen and (max-width: 834px) {
  .voice-heading {
    padding-left: 0;
    margin-bottom: 35px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.voice-heading img {
  margin-right: 25px;
}

@media screen and (max-width: 834px) {
  .voice-heading img {
    width: 40px;
    margin-right: 10px;
  }
}

.voice-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -19px;
}

@media screen and (max-width: 1200px) {
  .voice-list {
    margin: 0 -10px;
  }
}

.voice-item {
  width: 50%;
  padding: 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .voice-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 990px) {
  .voice-item {
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .voice-item {
    flex-wrap: wrap;
  }
  .voice-item:last-child {
    margin-bottom: 0;
  }
}

.voice-user {
  width: 132px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .voice-user {
    width: 100%;
    margin-bottom: 35px;
  }
}

.voice-user p {
  font-weight: 500;
  color: #363636;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .voice-user p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.voice-img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto 10px;
}

@media screen and (max-width: 834px) {
  .voice-img {
    width: 100px;
    height: 100px;
  }
}

.voice-chat {
  width: calc(100% - 172px);
  background: #fff;
  padding: 27px 29px 24px;
  border-radius: 15px;
  box-shadow: 5px 8.66px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

@media screen and (max-width: 1200px) {
  .voice-chat {
    padding: 20px;
  }
}

@media screen and (max-width: 834px) {
  .voice-chat {
    width: 100%;
    padding: 15px;
  }
}

.voice-chat:before {
  content: '';
  border: 17px solid transparent;
  border-right: 28px solid #fff;
  position: absolute;
  left: -44px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

@media screen and (max-width: 834px) {
  .voice-chat:before {
    border: 14px solid transparent;
    border-right: 29px solid #fff;
    left: 47.5%;
    transform: rotate(90deg) translate(-50%, 0);
    top: -13px;
  }
}

@media screen and (max-width: 640px) {
  .voice-chat:before {
    left: 43.5%;
  }
}

.voice-ttl {
  font-weight: bold;
  color: #363636;
  margin-bottom: 20px;
  letter-spacing: 2px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 1200px) {
  .voice-ttl {
    letter-spacing: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 374px) {
  .voice-ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.voice-desc {
  color: #363636;
  line-height: 1.8;
  letter-spacing: 2px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 1200px) {
  .voice-desc {
    letter-spacing: 0;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.sec-company {
  background: url("../img/index/bg-02.png") center repeat;
  background-size: contain;
  padding: 41px 0 81px;
}

@media screen and (max-width: 834px) {
  .sec-company {
    padding: 50px 0;
  }
}

.company-flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .company-flex {
    flex-wrap: wrap;
  }
}

.company-flex__img {
  width: 50%;
  padding-right: 39px;
}

@media screen and (max-width: 990px) {
  .company-flex__img {
    padding-right: 20px;
  }
}

@media screen and (max-width: 834px) {
  .company-flex__img {
    width: 100%;
    margin-bottom: 25px;
    padding-right: 0;
  }
}

.company-flex__img img {
  width: 100%;
}

.company-flex__ct {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .company-flex__ct {
    width: 100%;
  }
}

.company-flex__ct dl {
  display: flex;
  align-items: center;
  border-right: 1px solid #6eb944;
}

.company-flex__ct dl:nth-child(1) dt {
  border-top: 1px solid #6eb944;
}

.company-flex__ct dl:nth-child(1) dd {
  border-top: 1px solid #6eb944;
}

.company-flex__ct dl:last-child dt {
  border-bottom: 1px solid #6eb944;
}

.company-flex__ct dl dt {
  background: #6eb944;
  width: 160px;
  border-bottom: 1px solid #fff;
  line-height: 1.8;
  color: #fff;
  padding: 14px 20px 14px 17px;
  letter-spacing: 1px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .company-flex__ct dl dt {
    padding: 10px;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 990px) {
  .company-flex__ct dl dt {
    line-height: 1.6;
    width: 120px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  .company-flex__ct dl dt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.company-flex__ct dl dd {
  width: calc(100% - 160px);
  background: #fff;
  border-bottom: 1px solid #6eb944;
  color: #363636;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.8;
  padding: 14px 30px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .company-flex__ct dl dd {
    letter-spacing: 0;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 990px) {
  .company-flex__ct dl dd {
    width: calc(100% - 120px);
    line-height: 1.6;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .company-flex__ct dl dd {
    padding: 10px;
  }
}

@media screen and (max-width: 374px) {
  .company-flex__ct dl dd {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.company-flex__ct dl dd a {
  color: #363636;
}

.company-flex__ct dl dd .map {
  background: #6eb944;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  padding: 3px 13px 6px;
  margin-left: 13px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 990px) {
  .company-flex__ct dl dd .map {
    padding: 2px 10px 5px;
    margin-left: 8px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}
