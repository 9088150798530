.sec-mv{
	position: relative;
	.mv-ttl{
		position: absolute;
		top: 66%;
		transform: translateY(-50%);
		right: 3%;
		background: #7abf56;
		width: 300px;
		height: 300px;
		border-radius: 50%;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-wrap: wrap;
		align-content: center;
		padding: 10px 10px 44px;
		@include font-size(36);
		@include mq(xl){
			width: 200px;
			height: 200px;
			padding: 10px;
			@include font-size(24);
		}
		@include mq(sm){
			width: 150px;
			height: 150px;
			@include font-size(18);
		}
		span{
			display: block;
			width: 100%;
			margin-bottom: 5px;
			@include font-size(24);
			@include mq(xl){
				@include font-size(20);
			}
			@include mq(sm){
				margin-bottom: 2px;
				@include font-size(16);
			}
		}
	}
}
.slider-home{
	.item{
		outline: none !important;
		img{
			width: 100%;
			min-height: 700px;
			object-fit: cover;
			font-family: 'object-fit: cover';
			@include mq(lg){
				min-height: 500px;
			}
			@include mq(sm){
				min-height: 400px;
			}
		}
	}
	.slick-dots{
		bottom: 20px;
		@include mq(){
			bottom: 12px;
		}
		li{
			width: 10px;
			height: 10px;
			margin: 0 5px;
			border-radius: 50%;
			background: #fff;
			border: 1px solid #6eb944;
			&.slick-active{
				background: #6eb944;
			}
			button{
				&:before{
					display: none;
				}
			}
		}
	}
}

.sec-problems{
	background: url('../img/index/bg-02.png') center repeat;
	background-size: contain;
	padding: 72px 0 67px;
	@include mq(){
		padding: 50px 0;
	}
	.txt-basic{
		text-align: center;
	}
}
.heading-ttl{
	margin-bottom: 90px;
	@include mq(){
		margin-bottom: 50px;
	}
}

.ttlcomp-01{
	text-align: center;
	color: #6eb944;
	font-family: $font-Notoserif;
	padding-bottom: 28px;
	margin-bottom: 29px;
	letter-spacing: 3px;
	position: relative;
	font-weight: bold;
	@include font-size(30);
	@include mq(lg){
		@include font-size(24);
	}
	@include mq(){
		padding-bottom: 12px;
		margin-bottom: 20px;
		letter-spacing: 0;
		@include font-size(20);
	}
	&:before{
		content: '';
		background: #6eb944;
		width: 80px;
		height: 2px;
		bottom: 0;
		left: 50.25%;
		position: absolute;
		transform: translateX(-50%);
		@include mq(){
			left: 50%;
			width: 50px;
		}
	}
}

.problems{
	&-box{
		position: relative;
	}
	&-ttl{
		position: absolute;
		top: -46px;
		left: 50%;
		display: inline-block;
		padding: 14px 50px 20px;
		background: #6eb944;
		transform: translateX(-50%);
		color: #fff;
		font-weight: bold;
		letter-spacing: 2.5px;
		text-align: center;
		@include font-size(24);
		@include mq(xl){
			letter-spacing: 0;
			padding: 10px 20px;
			top: -35px;
			@include font-size(20);
		}
		@include mq(){
			padding: 10px;
			transform: translateX(0);
			position: static;
			@include font-size(18);
		}
		span{
			display: block;
			margin-top: 6px;
			letter-spacing: 2px;
			@include font-size(16);
			@include mq(xl){
				letter-spacing: 0;
				@include font-size(14);
			}
			@include mq(){
				@include font-size(13);
			}
		}
	}
	&-flex{
		border: 4px solid #6eb944;
		display: flex;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
		@include mq(xl){
			align-items: center;
		}
		@include mq(){
			flex-wrap: wrap;
			border-width: 2px;
			margin-bottom: 35px;
		}
	}
	&-img{
		width: 50%;
		@include mq(){
			width: 100%;
		}
		img{
			width: 100%;
		}
	}
	&-list{
		width: 50%;
		padding: 72px 34px 50px;
		@include mq(xl){
			padding: 40px 20px;
		}
		@include mq(){
			padding: 20px 15px;
			width: 100%;
		}
	}
	&-btn{
		margin-top: -37px !important;
		margin-bottom: 61px !important;
		@include mq(lg){
			margin-top: -30px !important;
		}
		@include mq(){
			margin-top: 0 !important;
			margin-bottom: 50px !important;
		}
	}
	&-item{
		margin-bottom: 27px;
		display: flex;
		width: 100%;
		align-items: center;
		@include mq(xl){
			margin-bottom: 20px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		&__img{
			width: 140px;
			height: 140px;
			border-radius: 50%;
			@include mq(xl){
				width: 90px;
				height: 90px;
			}
		}
		&__ttl{
			padding-left: 27px;
			width: calc(100% - 140px);
			color: #363636;
			font-weight: bold;
			display: flex;
			align-items: center;
			line-height: 1.2;
			letter-spacing: 2px;
			padding-bottom: 6px;
			@include font-size(18);
			@include mq(xl){
				letter-spacing: 0;
				padding-left: 15px;
				width: calc(100% - 90px);
				@include font-size(16);
			}
			@include mq(lg){
				@include font-size(14);
			}
			@include mq(){
				flex-wrap: wrap;
			}
			span{
				padding-left: 9px;
				letter-spacing: 3px;
				font-family: $font-Oswald;
				@include font-size(30);
				@include mq(xl){
					letter-spacing: 0;
					@include font-size(24);
				}
				@include mq(){
					width: 100%;
					display: block;
					margin-top: 5px;
					text-align: left;
					padding-left: 0;
				}
			}
		}
	}
}

.btn{
	background: #00b1dd;
	font-weight: 500;
	color: #fff;
	max-width: 513px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin: 0 auto;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border-radius: 60px;
	padding: 10px 40px 10px 10px;
	letter-spacing: 1.85px;
	position: relative;
	@include font-size(18);
	@include mq(lg){
		height: 60px;
		max-width: 460px;
		@include font-size(16);
	}
	@include mq(){
		text-align: center;
		padding: 10px 20px;
		max-width: 300px;
	}
	&:before{
		content: '';
		background: url('../img/index/icon-link.png') center no-repeat;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 21px;
		@include mq(lg){
			right: 15px;
		}
	}
	&-style02{
		height: 60px;
		max-width: 350px;
		@include mq(){
			max-width: 300px;
		}
	}
}

.cleaning{
	&-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 4px solid #3adaaf;
		background: #fff;
		padding: 0 31px 0 0;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
		@include mq(xl){
			padding: 0 15px 0 0;
		}
		@include mq(lg){
			flex-wrap: wrap;
			padding: 0;
			text-align: center;
		}
		@include mq(){
			border-width: 2px;
		}
		.btn{
			max-width: 205px;
			height: 59px;
			margin-right: 0;
			@include mq(lg){
				margin: 0 auto 20px;
			}
		}
	}
	&-img{
		width: 238px;
		@include mq(xl){
			width: 200px;
		}
		@include mq(lg){
			width: 100%;
			margin-bottom: 12px;
		}
		img{
			width: 100%;
		}
	}
	&-ttl{
		padding-left: 26px;
		font-weight: bold;
		color: #3adaaf;
		letter-spacing: 2px;
		@include font-size(20);
		@include mq(xl){
			letter-spacing: 0;
			padding-left: 15px;
			@include font-size(18);
		}
		@include mq(lg){
			width: 100%;
			padding: 0 15px;
			margin-bottom: 10px;
		}
	}
	&-price{
		padding-left: 50px;
		font-weight: bold;
		color: #363636;
		letter-spacing: 1.5px;
		display: flex;
		align-items: center;
		padding-right: 15px;
		@include font-size(16);
		@include mq(xl){
			padding-left: 20px;
			@include font-size(12);
		}
		@include mq(lg){
			width: 100%;
			padding: 0 15px;
			justify-content: center;
			margin-bottom: 10px;
		}
		span{
			letter-spacing: 2.5px;
			font-family: $font-Oswald;
			padding-left: 10px;
			@include font-size(25);
			@include mq(xl){
				padding-left: 5px;
				@include font-size(20);
			}
		}
	}
}

.sec-work{
	padding: 80px 0 100px;
	background: url('../img/index/bg-01.png') #f0f8ec top center no-repeat;
	background-size: cover;
	@include mq(){
		padding: 50px 0;
	}
}

.work{
	&-list{
		display: flex;
		margin: 0 -14px 50px;
		@include mq(){
			flex-wrap: wrap;
			margin: 0 0 35px;
		}
	}
	&-item{
		padding: 0 14px;
		width: 33.33%;
		@include mq(){
			width: 100%;
			padding: 0;
			margin-bottom: 35px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		a{
			display: block;
			border: 2px solid #6eb944;
			border-radius: 10px;
			padding: 18px 18px 14px;
			background: #fff;
			@include mq(lg){
				padding: 12px;
			}
		}
	}
	&-img{
		margin-bottom: 17px;
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(320/340*100%);
		}
		.thumbnail {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		img{
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			font-family: "object-fit: cover";
		}
	}
	&-ttl{
		margin-bottom: 12px;
		text-align: center;
		line-height: 1.2;
		letter-spacing: 2px;
		font-weight: bold;
		color: #6eb944;
		@include font-size(22);
		@include mq(lg){
			@include font-size(20);
		}
	}
	&-desc{
		color: #363636;
		line-height: 1.8;
		letter-spacing: 1.75px;
		@include font-size(14);
	}
}

.voice{
	&-box{
		border-radius: 20px;
		background: #6eb944;
		padding: 35px 20px 31px;
		margin-top: 60px;
		@include mq(){
			margin-top: 50px;
			padding: 50px 15px;
			border-radius: 10px;
		}
	}
	&-heading{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 57px;
		color: #fff;
		font-weight: bold;
		letter-spacing: 3px;
		padding-left: 41px;
		@include font-size(26);
		@include mq(){
			padding-left: 0;
			margin-bottom: 35px;
			@include font-size(22);
		}
		img{
			margin-right: 25px;
			@include mq(){
				width: 40px;
				margin-right: 10px;
			}
		}
	}
	&-list{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -19px;
		@include mq(xl){
			margin: 0 -10px;
		}
	}
	&-item{
		width: 50%;
		padding: 0 19px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		@include mq(xl){
			padding: 0 10px;
		}
		@include mq(lg){
			width: 100%;
		}
		@include mq(){
			flex-wrap: wrap;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&-user{
		width: 132px;
		text-align: center;
		@include mq(){
			width: 100%;
			margin-bottom: 35px;
		}
		p{
			font-weight: 500;
			color: #363636;
			@include font-size(16);
			@include mq(){
				@include font-size(14);
			}
		}
	}
	&-img{
		width: 110px;
		height: 110px;
		border-radius: 50%;
		background: #fff;
		margin: 0 auto 10px;
		@include mq(){
			width: 100px;
			height: 100px;
		}
	}
	&-chat{
		width: calc(100% - 172px);
		background: #fff;
		padding: 27px 29px 24px;
		border-radius: 15px;
		box-shadow: 5px 8.66px 20px 0 rgba(0, 0, 0, 0.2);
		position: relative;
		@include mq(xl){
			padding: 20px;
		}
		@include mq(){
			width: 100%;
			padding: 15px;
		}
		&:before{
			content: '';
			border: 17px solid transparent;
			border-right: 28px solid #fff;
			position: absolute;
			left: -44px;
			top: 50%;
			z-index: 9;
			transform: translateY(-50%);
			@include mq(){
				border: 14px solid transparent;
				border-right: 29px solid #fff;
				left: 47.5%;
				transform: rotate(90deg) translate(-50%, 0);
				top: -13px;
			}
			@include mq(sm){
				left: 43.5%;
			}
		}
	}
	&-ttl{
		font-weight: bold;
		color: #363636;
		margin-bottom: 20px;
		letter-spacing: 2px;
		@include font-size(20);
		@include mq(xl){
			letter-spacing: 0;
			margin-bottom: 10px;
			@include font-size(18);
		}
		@include mq(xs){
			@include font-size(16);
		}
	}
	&-desc{
		color: #363636;
		line-height: 1.8;
		letter-spacing: 2px;
		@include font-size(14);
		@include mq(xl){
			letter-spacing: 0;
			@include font-size(12);
		}
	}
}

.sec-company{
	background: url('../img/index/bg-02.png') center repeat;
	background-size: contain;
	padding: 41px 0 81px;
	@include mq(){
		padding: 50px 0;
	}
}

.company{
	&-flex{
		display: flex;
		align-items: center;
		@include mq(){
			flex-wrap: wrap;
		}
		&__img{
			width: 50%;
			padding-right: 39px;
			@include mq(lg){
				padding-right: 20px;
			}
			@include mq(){
				width: 100%;
				margin-bottom: 25px;
				padding-right: 0;
			}
			img{
				width: 100%;
			}
		}
		&__ct{
			width: 50%;
			@include mq(){
				width: 100%;
			}
			dl{
				display: flex;
				align-items: center;
				border-right: 1px solid #6eb944;
				&:nth-child(1){
					dt{
						border-top: 1px solid #6eb944;
					}
					dd{
						border-top: 1px solid #6eb944;
					}
				}
				&:last-child{
					dt{
						border-bottom: 1px solid #6eb944;
					}
				}
				dt{
					background: #6eb944;
					width: 160px;
					border-bottom: 1px solid #fff;
					line-height: 1.8;
					color: #fff;
					padding: 14px 20px 14px 17px;
					letter-spacing: 1px;
					font-weight: bold;
					display: flex;
					align-items: center;
					justify-content: center;
					@include font-size(16);
					@include mq(xl){
						padding: 10px;
						letter-spacing: 0;
					}
					@include mq(lg){
						line-height: 1.6;
						width: 120px;
						@include font-size(14);
					}
					@include mq(xs){
						@include font-size(13);
					}
				}
				dd{
					width: calc(100% - 160px);
					background: #fff;
					border-bottom: 1px solid #6eb944;
					color: #363636;
					font-weight: 500;
					letter-spacing: 1.5px;
					line-height: 1.8;
					padding: 14px 30px;
					@include font-size(16);
					@include mq(xl){
						letter-spacing: 0;
						padding: 10px 15px;
					}
					@include mq(lg){
						width: calc(100% - 120px);
						line-height: 1.6;
						@include font-size(14);
					}
					@include mq(){
						padding: 10px;
					}
					@include mq(xs){
						@include font-size(13);
					}
					a{
						color: #363636;
					}
					.map{
						background: #6eb944;
						color: #fff;
						font-weight: bold;
						line-height: 1;
						display: inline-block;
						padding: 3px 13px 6px;
						margin-left: 13px;
						@include font-size(16);
						@include mq(lg){
							padding: 2px 10px 5px;
							margin-left: 8px;
							@include font-size(14);
						}
					}
				}
			}
		}
	}
}
























