
body {
	color: #363636;
	font-family: $font-notoJp;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow-x: hidden;
	//font-feature-settings: "palt" 1;
}


//header
#header{
	position: fixed;
	background: rgba(#fff,0.8);
	padding: 0 80px;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 99;
	@include mq(lp){
		padding: 0 20px;
	}
	@include mq(xl){
		padding: 0 0 0 20px;
	}
	@include mq(lg){
		padding: 18px 20px;
	}
	.inner{
		display: flex;
		max-width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		.logo{
			width: 250px;
			@include mq(xl){
				width: 180px;
			}
		}
		.nav-menu{
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include mq(lg){
				display: none;
				position: absolute;
				top: 63px;
				left: 0;
				right: 0;
				height: 100vh;
				background: rgba(#fff,0.8);
				padding: 35px 15px 80px;
				overflow-y: scroll;
				&.active{
					display: block;
				}
			}
			.show-menu{
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				margin-right: 19px;
				width: calc(100% - 230px);
				@include mq(lp){
					margin-right: 10px;
				}
				@include mq(xl){
					width: calc(100% - 200px);
				}
				@include mq(lg){
					flex-wrap: wrap;
					width: 100%;
					margin-bottom: 35px;
					border-top: 1px solid #949494;
				}
				>.item{
					position: relative;
					padding: 0 14px;
					@include mq(xl){
						padding: 0 10px;
					}
					@include mq(lg){
						width: 50%;
						padding: 0;
						&:nth-child(2n){
							a{
								border-left: 1px solid #949494;
							}
						}
					}
					>a{
						display: block;
						font-weight: bold;
						color: #363636;
						position: relative;
						padding: 10px 0;
						@include font-size(14);
						@include mq(xl){
							@include font-size(13);
						}
						@include mq(lg){
							padding: 10px 5px;
							text-align: center;
							border-bottom: 1px solid #949494;
						}
						&:before{
							content: '';
							background: #6eb944;
							width: 90%;
							height: 2px;
							bottom: 0;
							left: 50%;
							position: absolute;
							opacity: 0;
							transition: all 0.3s;
							transform: translateX(-50%);
						}
						&:hover{
							//opacity: 1;
							color: #6eb944;
							&:before{
								opacity: 1;
								transition: all 0.3s;
							}
						}
					}
					&.active{
						a{
							color: #6eb944;
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
			.phone-hd{
				width: 237px;
				text-align: center;
				background: #ff8331;
				padding: 3px 10px 18px 5px;
				@include mq(xl){
					width: 200px;
					padding: 10px;
				}
				@include mq(lg){
					width: 220px;
					margin: 0 auto;
				}
				a{
					color: #fff;
					display: block;
					font-weight: 600;
					line-height: 1.34;
					font-family: $font-Oswald;
					letter-spacing: 0.5px;
					@include font-size(24);
					@include mq(xl){
						@include font-size(20);
					}
					span{
						font-weight: bold;
						margin-bottom: 5px;
						letter-spacing: 0;
						font-family: $font-notoJp;
						@include font-size(12);
						display: block;
					}
					img{
						margin-top: -5px;
					}
				}
			}
		}
		.mobile-icon {
			width: 26px;
			height: 30px;
			-webkit-transition: background .5s;
			-o-transition: background .5s;
			transition: background .5s;
			position: absolute;
			right: 20px;
			top: 51%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			cursor: pointer;
			z-index: 12;
			display: none;
			@include mq(lg){
				display: block;
			}
			span{
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				display: block;
				width: 100%;
				height: 2px;
				margin-top: -2px;
				background-color: #333;
				font-size: 0;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				-webkit-transition: background-color 0.3s;
				-o-transition: background-color 0.3s;
				transition: background-color 0.3s;
				border-radius: 3px;
				&:before,&:after{
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					background: #333;
					content: '';
					-webkit-transition: -webkit-transform 0.3s;
					transition: transform 0.3s;
					border-radius: 3px;
				}
				&:before{
					-webkit-transform: translateY(-250%);
					transform: translateY(-250%);
					top: -4px;
				}
				&:after{
					-webkit-transform: translateY(250%);
					transform: translateY(250%);
					bottom: -4px;
				}
			}
			&.mobile-close span {
				background-color: transparent;
				&:before,&:after{
					width: 100%;
					background: #333;
				}
				&:before {
					-webkit-transform: translateY(0) rotate(45deg);
					transform: translateY(0) rotate(45deg);
					top: 2px;
				}
				&:after {
					-webkit-transform: translateY(0) rotate(-45deg);
					transform: translateY(0) rotate(-45deg);
					bottom: -2px;
				}
			}
		}
	}
}


//footer
#footer{
	border-top: 2px solid #6eb944;
	padding: 42px 0 36px;
	.footer-flex{
		display: flex;
		align-items: center;
		@include mq(lg){
			flex-wrap: wrap;
		}
		.logo-ft{
			width: 252px;
			@include mq(lg){
				width: 100%;
				margin-bottom: 20px;
				text-align: center;
			}
			@include mq(){
				img{
					width: 180px;
				}
			}
		}
		.phone-ft{
			width: 233px;
			color: #ff8331;
			font-weight: 600;
			line-height: 1.2;
			font-family: $font-Oswald;
			letter-spacing: 0.5px;
			@include font-size(30);
			@include mq(lg){
				width: 200px;
				@include font-size(24);
			}
			@include mq(){
				margin: 0 auto;
			}
			span{
				letter-spacing: 0;
				color: #363636;
				text-align: center;
				line-height: 1.315;
				display: block;
				font-weight: bold;
				margin-bottom: 10px;
				transform: translateX(8px);
				font-family: $font-notoJp;
				@include font-size(14);
				@include mq(lg){
					@include font-size(12);
				}
			}
		}
		.nav-ft{
			width: calc(100% - 460px);
			padding: 0 30px 0 22px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 9px -11px -12px;
			@include mq(lg){
				width: calc(100% - 200px);
			}
			@include mq(){
				width: 100%;
				margin: 0 0 20px;
				padding: 0;
			}
			li{
				padding: 0 13px;
				margin-bottom: 15px;
				@include mq(lg){
					padding: 0 9px;
				}
				@include mq(){
					padding: 0 12px;
				}
				a{
					color: #363636;
					transition: all 0.3s;
					@include font-size(14);
					@include mq(lg){
						@include font-size(13);
					}
					&:hover{
						color: #6eb944;
						transition: all 0.3s;
					}
				}
			}
		}
	}
}

.copy-right{
	background: #6eb944;
	padding: 10px 34px 14px 20px;
	text-align: center;
	color: #fff;
	font-weight: bold;
	letter-spacing: 1px;
	@include font-size(12);
	@include mq(){
		width: 100%;
		padding: 10px 10px 12px;
	}
}

.is-locked {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
    body{
        -webkit-overflow-scrolling: auto;
    }
}